<template>
  <div>
    <!-- <v-tooltip>
      <v-tooltip-title> Cambio de estado </v-tooltip-title>
    </v-tooltip> -->
    <v-card class="pa-4">
      <v-list dense>
        <v-list-item-group v-model="nuevoEstado">
          <v-list-item
            v-for="estado in estados"
            :value="estado"
            :key="estado.idEstado"
          >
            <v-chip class="v-chip--active" outlined label :color="estado.color">
              <v-icon left>{{ estado.icon }}</v-icon>
              {{ estado.nombreEstado }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <div class="d-flex ma-4" v-if="nuevoEstado">
        <v-chip
          class="v-chip--active"
          outlined
          label
          :color="nuevoEstado.color"
        >
          <v-icon left>{{ nuevoEstado.icon }}</v-icon>
          {{ nuevoEstado.nombreEstado }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click.stop="cambiar">Confirmar</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    idEvento: Number,
  },
  data() {
    return {
      estados: [],
      nuevoEstado: null,
    };
  },
  methods: {
    getEstados() {
      return new Promise(async (resolve) => {
        const { data } = await axios(`/opciones/estados`);
        this.estados = data;
        resolve();
      });
    },
    cambiar() {
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/eventos/cambioEstado`,
        data: {
          idEvento: this.idEvento,
          nuevoEstado: this.nuevoEstado.idEstado,
        },
      }).then((res) => {
        this.$emit("reload");
        this.$emit("close");
        this.$root.$emit("snack", 'Se ha cambiado el estado con éxito');
      }).catch(err => {
				console.error(err)
        this.$root.$emit("snack", 'No se ha podido cambiar el estado');
        this.$emit("close");
			})
    },
  },
  mounted() {
    this.getEstados();
  },
};
</script>

<style>
</style>